import { ErrorMessage } from '@hookform/error-message'
import { Button } from 'components/reusables/Button'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import FormsService from 'services/FormsService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Styled from './styles'

interface FormInputs {
  phone: string
}

export const CheckPhoneForm = (): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
  })

  const Alert = withReactContent(Swal)

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    Alert.fire({
      title: 'Verificando...',
      html: 'Por favor espere enquanto nós processamos sua requisição',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    try {
      const response = await FormsService.sendPhoneFind(data)
      Swal.close()

      if (response.data.data == 'found') {
        Alert.fire({
          title: 'Sucesso!',
          text: response.data.message,
          icon: 'success',
          confirmButtonColor: '#CA1F34',
        })
      } else {
        Alert.fire({
          title: 'Erro!',
          text: response.data.message,
          icon: 'error',
          confirmButtonColor: '#CA1F34',
        })
      }
    } catch (err) {
      Alert.fire({
        title: 'Erro!',
        text: 'Erro na busca',
        icon: 'error',
        confirmButtonColor: '#CA1F34',
      })
    }
  }
  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <Styled.FormGroup style={{ marginTop: '50px' }}>
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Preencha este campo',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Styled.FormInput
              as={InputMask}
              mask="(99) 999999999"
              maskChar={null}
              placeholder="Informe o número*"
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors?.phone && (
          <ErrorMessage
            errors={errors}
            name="phone"
            render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
          />
        )}
      </Styled.FormGroup>
      <Button type="submit" variant="tertiary" width={'30%'} mt={20}>
        VERIFICAR
      </Button>
    </Styled.Form>
  )
}
